import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";

export const get_seller_request = createAsyncThunk(
  "seller/get_seller_request",
  async (
    { parPage, page, searchValue },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const { data } = await api.get(
        `/request-seller-get?page=${page}&&searchValue=${searchValue}&&parPage=${parPage}`,
        { withCredentials: true }
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const get_seller = createAsyncThunk(
  "seller/get_seller",
  async (sellerId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/get-seller/${sellerId}`, {
        withCredentials: true,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const seller_status_update = createAsyncThunk(
  "seller/seller_status_update",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(`/seller-status-update`, info, {
        withCredentials: true,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const get_active_sellers = createAsyncThunk(
  "seller/get_active_sellers",
  async (
    { parPage, page, searchValue },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const { data } = await api.get(
        `/get-sellers?page=${page}&&searchValue=${searchValue}&&parPage=${parPage}`,
        { withCredentials: true }
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const edit_shop = createAsyncThunk(
  "seller/edit-shop",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(`/edit-shop`, info, {
        withCredentials: true,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const edit_seller = createAsyncThunk(
  "seller/edit-seller",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(`/edit-seller`, info, {
        withCredentials: true,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const get_store = createAsyncThunk(
  "home/get-store",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/home/store/${info}?withId=true`, {
        withCredentials: true,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const get_store_data = createAsyncThunk(
  "home/get-store-data",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(
        `/home/store/${info}?all=true&withId=true`,
        {
          withCredentials: true,
        }
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const get_admins = createAsyncThunk(
  "admin/get_admins",
  async (
    { parPage, page, searchValue },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const { data } = await api.get(
        `/get-admins?page=${page}&&searchValue=${searchValue}&&parPage=${parPage}`,
        { withCredentials: true }
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const delete_admin = createAsyncThunk(
  "admin/delete_admin",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(`/delete-admin`, info, {
        withCredentials: true,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const get_subs = createAsyncThunk(
  "seller/get_subs",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/subs`, {
        withCredentials: true,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const edit_sub = createAsyncThunk(
  "admin/edit_sub",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.put(`/subs`, info, {
        withCredentials: true,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const delete_sub = createAsyncThunk(
  "admin/delete_sub",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.delete(`/subs`, {
        withCredentials: true,
        data: info,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const get_sellers = createAsyncThunk("seller/get_sellers", async () => {
  try {
    const { data } = await api.get("/get-all-sellers", {
      withCredentials: true,
    });
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const get_deactive_sellers = createAsyncThunk(
  "seller/get_active_sellers",
  async (
    { parPage, page, searchValue },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const { data } = await api.get(
        `/get-deactive-sellers?page=${page}&&searchValue=${searchValue}&&parPage=${parPage}`,
        { withCredentials: true }
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const payFees = createAsyncThunk(
  "seller/payFees",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(`/payment/create-checkout`, info, {
        withCredentials: true,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSeller = createAsyncThunk(
  "seller/updateSeller",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(`/payment/update-seller`, info, {
        withCredentials: true,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const subscribe = createAsyncThunk(
  "seller/subscribe",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(`/payment/subscribe`, info, {
        withCredentials: true,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sellerReducer = createSlice({
  name: "seller",
  initialState: {
    successMessage: "",
    errorMessage: "",
    loader: false,
    sellers: [],
    all_sellers: [],
    totalSeller: 0,
    seller: "",
    admins: [],
    totalAdmins: 0,
    subs: [],
    checkout: [],
  },
  reducers: {
    messageClear: (state, _) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
  extraReducers: {
    [get_seller_request.fulfilled]: (state, { payload }) => {
      state.sellers = payload.sellers;
      state.totalSeller = payload.totalSeller;
    },
    [get_seller.fulfilled]: (state, { payload }) => {
      state.seller = payload.seller;
    },
    [seller_status_update.fulfilled]: (state, { payload }) => {
      state.seller = payload.seller;
      state.successMessage = payload.message;
    },
    [get_active_sellers.fulfilled]: (state, { payload }) => {
      state.sellers = payload.sellers;
      state.totalSeller = payload.totalSeller;
    },
    [get_sellers.fulfilled]: (state, { payload }) => {
      state.all_sellers = payload.all_sellers;
      state.totalSellers = payload.totalSellers;
    },
    [get_admins.fulfilled]: (state, { payload }) => {
      state.admins = payload.admins;
      state.totalAdmins = payload.totalAdmins;
    },
    [edit_shop.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
    },
    [edit_seller.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
    },
    [get_store.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
    },
    [get_store_data.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
    },
    [get_subs.fulfilled]: (state, { payload }) => {
      state.subs = payload.subs;
    },
    [edit_sub.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
    },
    [delete_sub.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
    },
    [payFees.fulfilled]: (state, { payload }) => {
      state.checkout = payload.checkout;
      state.errorMessage = payload.message;
    },
    [updateSeller.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
    },
    [subscribe.fulfilled]: (state, { payload }) => {
      state.checkout = payload.checkout;
    },
  },
});
export const { messageClear } = sellerReducer.actions;
export default sellerReducer.reducer;
